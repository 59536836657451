<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-28 16:29:09
 * @LastEditTime: 2021-09-29 16:20:34
 * @Description: 混凝土站点拓量日报
 * @Param:
 * @FilePath: \JT_Web_test\src\views\statisticalAnalysis\statisticalReport\concreteDaily.vue
 -->

<template>
    <div class="concreteDaily" v-loading="loading">
        <div class="concreteDaily_content reportForm_style">
            <div class="reportForm_head">
                <div class="queryCriteria">
                    <div>
                        <el-date-picker
                            v-model="date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </div>
                    <div>
                        <h3>平台名称：</h3>
                        <el-select v-model="platformID" placeholder="请选择" @change="selectPlatform">
                            <el-option
                                v-for="item in platformOptions"
                                :key="item.tenan_id"
                                :label="item.tenan_name_abbreviation"
                                :value="item.tenan_id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <h3>站点名称：</h3>
                        <el-select v-model="stationID" placeholder="请选择">
                            <el-option
                                v-for="item in stationOptions"
                                :key="item.station_id"
                                :label="item.station_name"
                                :value="item.station_id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-button type="primary" @click="query">
                            查询
                        </el-button>
                    </div>
                </div>
                <div class="company">
                    <h3>单位：方</h3>
                </div>
            </div>
            <div class="concreteDaily_table">
                <div class="tableBox">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <p style="width:1.2rem;">
                                        平台名称
                                    </p>
                                </th>
                                <th>
                                    <p style="width:1.2rem;">
                                        站点名称
                                    </p>
                                </th>
                                <template v-for="item in tableDate">
                                    <th :key="`date_${item}`">
                                        <p>{{ item }}日</p>
                                    </th>
                                </template>
                                <th class="cumulative">
                                    <p>月累计</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, index) in tableData">
                                <tr :key="`list_${index}`">
                                    <th :rowspan="item.StationDtoList.length">
                                        {{ item.tenant_name }}
                                    </th>
                                    <template v-for="(k,j) in item.StationDtoList">
                                        <template v-if="j === 0">
                                            <th :key="`station_${k.station_id}`">
                                                {{ k.station_name }}
                                            </th>
                                            <template v-for="(v, b) in k.DateDtoList">
                                                <td :key="`squareQuantity_${b}`">
                                                    {{ v.day_volume }}
                                                </td>
                                            </template>
                                            <td :key="`cumulative_${k.station_id}`" class="cumulative">
                                                {{ k.sumtotal }}
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                                <template v-for="(k,j) in item.StationDtoList">
                                    <tr :key="`station_${k.station_id}`" v-if="j > 0">
                                        <template>
                                            <th>
                                                {{ k.station_name }}
                                            </th>
                                        </template>
                                        <template v-for="(v, b) in k.DateDtoList">
                                            <td :key="`squareQuantity_${b}`">
                                                {{ v.day_volume }}
                                            </td>
                                        </template>
                                        <td :key="`cumulative_${k.station_id}`" class="cumulative">
                                            {{ k.sumtotal }}
                                        </td>
                                    </tr>
                                </template>
                                <tr :key="`total_${index}`" class="subtotal">
                                    <td colspan="2">
                                        小计
                                    </td>
                                    <template v-for="(g,h) in item.dto">
                                        <td :key="`totalRow_${h}`">
                                            {{ g.day_volume }}
                                        </td>
                                    </template>
                                    <td class="cumulative">
                                        {{ item.sumtotal }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/utils/util';

export default {
    name: 'concrete-daily',
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            date: '',
            platformOptions: [],
            platformID: '',
            stationOptions: [],
            stationID: '',
            tableData: [],
            tableDate: [],
            monthlyAccumulationData: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.initPage();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async initPage() {
            this.loading = true;
            this.date = utils.currentTimeFormat('yyyy-MM-dd');
            this.platformOptions = await this.getPlatformData();
            this.initTable();
        },
        // 选择平台
        async selectPlatform() {
            this.stationOptions = await this.getStationData(this.platformID);
        },
        // 查询
        query() {
            this.initTable();
        },
        // 初始化列表
        async initTable() {
            this.loading = true;
            this.loadTableHeader();
            this.tableData = await this.getTableData();
            this.processListData(this.tableData);
            this.loading = false;
        },
        // 获取平台数据
        getPlatformData() {
            return this.$axios.$get('/interfaceApi/datacenter/thematic/sales/gettenant/data', { defEx: true });
        },
        // 获取平台下场站数据
        getStationData(tenant_id = '') {
            return this.$axios.$get(`/interfaceApi/report/largescreen3/czxx?tenant_id=${tenant_id}`, { defEx: true });
        },
        // 获取列表数据
        getTableData() {
            // eslint-disable-next-line max-len
            const Url = `/interfaceApi/datacenter/thematic/sales/getthematic_sales/total?date=${this.date}&tenant_id=${this.platformID}&station_id=${this.stationID}`;
            return this.$axios.$get(Url, { defEx: true });
        },
        // 加载列表头部数据
        loadTableHeader() {
            const time = this.date.split('-');
            const days = new Date(time[0], time[1], 0).getDate();
            this.tableDate = [...Array(days)].map((k, i) => i + 1);
        },
        // 处理列表数据
        processListData(data) {
            const row = [];
            data.forEach(item => {
                item.StationDtoList.forEach(k => {
                    row.push(k.sumtotal);
                });
            });
            this.monthlyAccumulationData = row;
        },
    },
};
</script>
<style lang="stylus" scoped>
@import './CSS/index.stylus';
.concreteDaily
    height 100%
    padding 0.2rem
    .concreteDaily_content
        background #fff
        height 100%
        padding 0.14rem
    .concreteDaily_table
        height calc(100% - 0.5rem)
        overflow auto
        position relative
        border 1px solid #D7D7D7
        .tableBox
            display inline-block
        table
            min-width 100%
            table-layout fixed
            border-collapse collapse
            th,td
                border 1px solid #D7D7D7
                font-size 0.14rem
                line-height 0.36rem
                text-align center
                color #333333
                font-weight 400
                &:first-child
                    border-left none
                &:last-child
                    border-right none
                p
                    width 0.8rem
                &.cumulative
                    background #F5F2ED !important
                    color #823802 !important
                    font-weight 500
            thead
                th
                    background #EDF0F5
                    color #022782
                    border-top none
            tbody
                .subtotal
                    td
                        background #FEF9E4
                        color #FF6600
                th
                    background #F6F8FC
        .monthlyAccumulation
            width 1rem
            box-shadow 0 0 10px rgb(0 0 0 / 12%)
            position absolute
            top 0
            right 0
            td,th
                background #F5F2ED
                border 1px solid #D6CEC9
                color #823802
                font-weight 500
            th
                border-top none

</style>